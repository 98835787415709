.ui-provider {
	.a {
		background-color: transparent;
	}
}

.sub-header {
	border-bottom: 1px solid navy;
}

.panel-loading {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}