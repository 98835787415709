body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.rightHead {
	.ms-DetailsHeader-cellTitle {
		justify-content: flex-end;
	}
}

.rightCol.ms-DetailsRow-cell {
	text-align: right;
}

button {
	min-width: auto;
	margin: initial;
}

div#designerPage {
	height: calc(100% - 50px);

	& input[type='text'] {
		border: none;
	}
}

div.ms-Fabric {
	& input[type='text'],
	& input:not([type]) {
		border: none;
		// padding: 0;
	}
}

div.ms-Layer {
	& input[type='text'],
	& input:not([type]) {
		border: none;
	}
}

div.ms-Panel.designer {
	top: 90px;
	left: 320px;
	right: 218px;
	bottom: 102px;
}

div#designerPage .ms-Fabric {
	height: 100%;
	display: flex;
}

/* .ms-Label {
    height: 25px;
} */

// .ms-DatePicker .ms-TextField-fieldGroup {
// 	height: 26px !important;

// 	& .ms-TextField-field {
// 		height: 26px !important;
// 	}
// }

.Toastify {
	position: fixed;
	z-index: 9999999;
}

.Toastify__toast-body {
	white-space: pre-line;
}

input[type='password'][disabled],
input[type='text'][disabled],
input[type='file'][disabled],
input:not([type])[disabled],
textarea[disabled],
select[disabled],
.sp-peoplepicker-topLevelDisabled,
.ms-inputBoxDisabled {
	// background-color: initial;
	color: #333;
}

.rst__rowContents {
	min-width: 180px;
}

/*滚动条整体部分，可以设置宽度高度*/
::-webkit-scrollbar {
	background-color: #ccc;
	/* box-shadow: 3px 2px 3px red; */
	border-radius: 5px;
	width: 10px;
	height: 10px;
}

/*滚动条整体部分，可以设置宽度高度*/
::-webkit-scrollbar {
	background-color: #ccc;
	border-radius: 5px;
	width: 10px;
	height: 10px;
}

/*滚动条两端的按钮,点击该按钮，会使滚动条更接近这个按钮*/
::-webkit-scrollbar-button {
	display: none;
}

/*外层轨道*/
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/*内层滚动槽*/
::-webkit-scrollbar-track-piece {
	border-radius: 10px;
	background-color: #e0e0e0;
}

/*滚动的滑块,可以拖动的那个*/
::-webkit-scrollbar-thumb {
	background-color: #aaa;
	border-radius: 10px;
}

/*边角,只有横向和纵向滚动条都存在的时候，这个边角才会看得见*/
::-webkit-scrollbar-corner {
}

/*定义右下角拖动块的样式*/
::-webkit-resizer {
}

/* @font-face {
  font-family: 'FabricMDL2Icons';
  src: url('./dist/icons/fabric-icons-a13498cf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FabricMDL2Icons-0';
  src: url('./dist/icons/fabric-icons-0-467ee27f.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

.ms-Grid {
	box-sizing: border-box;
	zoom: 1;
	padding: 0 8px;
}

.ms-Grid::before,
.ms-Grid::after {
	display: table;
	content: '';
	line-height: 0;
}

.ms-Grid::after {
	clear: both;
}

.ms-Grid-row {
	margin: 0 -8px;
	box-sizing: border-box;
	zoom: 1;
}

.ms-Grid-row::before,
.ms-Grid-row::after {
	display: table;
	content: '';
	line-height: 0;
}

.ms-Grid-row::after {
	clear: both;
}

.ms-Grid-col {
	position: relative;
	min-height: 1px;
	padding-left: 8px;
	padding-right: 8px;
	box-sizing: border-box;
}

[dir='ltr'] .ms-Grid-col {
	float: left;
}

[dir='rtl'] .ms-Grid-col {
	float: right;
}

.ms-Grid-col .ms-Grid {
	padding: 0;
}

@media (max-width: 479.99999px) {
	.ms-hiddenSm,
	.ms-hiddenMdDown,
	.ms-hiddenLgDown,
	.ms-hiddenXlDown,
	.ms-hiddenXxlDown {
		display: none !important;
	}
}

@media (min-width: 480px) and (max-width: 639.99999px) {
	.ms-hiddenMd,
	.ms-hiddenMdUp,
	.ms-hiddenMdDown,
	.ms-hiddenLgDown,
	.ms-hiddenXlDown,
	.ms-hiddenXxlDown {
		display: none !important;
	}
}

@media (min-width: 640px) and (max-width: 1023.99999px) {
	.ms-hiddenMdUp,
	.ms-hiddenLg,
	.ms-hiddenLgUp,
	.ms-hiddenLgDown,
	.ms-hiddenXlDown,
	.ms-hiddenXxlDown {
		display: none !important;
	}
}

@media (min-width: 1024px) and (max-width: 1365.99999px) {
	.ms-hiddenMdUp,
	.ms-hiddenLgUp,
	.ms-hiddenXl,
	.ms-hiddenXlUp,
	.ms-hiddenXlDown,
	.ms-hiddenXxlDown {
		display: none !important;
	}
}

@media (min-width: 1366px) and (max-width: 1919.99999px) {
	.ms-hiddenMdUp,
	.ms-hiddenLgUp,
	.ms-hiddenXlUp,
	.ms-hiddenXxl,
	.ms-hiddenXxlUp,
	.ms-hiddenXxlDown {
		display: none !important;
	}
}

@media (min-width: 1920px) {
	.ms-hiddenMdUp,
	.ms-hiddenLgUp,
	.ms-hiddenXlUp,
	.ms-hiddenXxlUp,
	.ms-hiddenXxxl {
		display: none !important;
	}
}

.ms-sm12 {
	width: 100%;
}

.ms-sm11 {
	width: 91.66666666666666%;
}

.ms-sm10 {
	width: 83.33333333333334%;
}

.ms-sm9 {
	width: 75%;
}

.ms-sm8 {
	width: 66.66666666666666%;
}

.ms-sm7 {
	width: 58.333333333333336%;
}

.ms-sm6 {
	width: 50%;
}

.ms-sm5 {
	width: 41.66666666666667%;
}

.ms-sm4 {
	width: 33.33333333333333%;
}

.ms-sm3 {
	width: 25%;
}

.ms-sm2 {
	width: 16.666666666666664%;
}

.ms-sm1 {
	width: 8.333333333333332%;
}

[dir='ltr'] .ms-smPull12 {
	right: 100%;
}

[dir='rtl'] .ms-smPull12 {
	left: 100%;
}

[dir='ltr'] .ms-smPull11 {
	right: 91.66667%;
}

[dir='rtl'] .ms-smPull11 {
	left: 91.66667%;
}

[dir='ltr'] .ms-smPull10 {
	right: 83.33333%;
}

[dir='rtl'] .ms-smPull10 {
	left: 83.33333%;
}

[dir='ltr'] .ms-smPull9 {
	right: 75%;
}

[dir='rtl'] .ms-smPull9 {
	left: 75%;
}

[dir='ltr'] .ms-smPull8 {
	right: 66.66667%;
}

[dir='rtl'] .ms-smPull8 {
	left: 66.66667%;
}

[dir='ltr'] .ms-smPull7 {
	right: 58.33333%;
}

[dir='rtl'] .ms-smPull7 {
	left: 58.33333%;
}

[dir='ltr'] .ms-smPull6 {
	right: 50%;
}

[dir='rtl'] .ms-smPull6 {
	left: 50%;
}

[dir='ltr'] .ms-smPull5 {
	right: 41.66667%;
}

[dir='rtl'] .ms-smPull5 {
	left: 41.66667%;
}

[dir='ltr'] .ms-smPull4 {
	right: 33.33333%;
}

[dir='rtl'] .ms-smPull4 {
	left: 33.33333%;
}

[dir='ltr'] .ms-smPull3 {
	right: 25%;
}

[dir='rtl'] .ms-smPull3 {
	left: 25%;
}

[dir='ltr'] .ms-smPull2 {
	right: 16.66667%;
}

[dir='rtl'] .ms-smPull2 {
	left: 16.66667%;
}

[dir='ltr'] .ms-smPull1 {
	right: 8.33333%;
}

[dir='rtl'] .ms-smPull1 {
	left: 8.33333%;
}

[dir='ltr'] .ms-smPull0 {
	right: auto;
}

[dir='rtl'] .ms-smPull0 {
	left: auto;
}

[dir='ltr'] .ms-smPush12 {
	left: 100%;
}

[dir='rtl'] .ms-smPush12 {
	right: 100%;
}

[dir='ltr'] .ms-smPush11 {
	left: 91.66667%;
}

[dir='rtl'] .ms-smPush11 {
	right: 91.66667%;
}

[dir='ltr'] .ms-smPush10 {
	left: 83.33333%;
}

[dir='rtl'] .ms-smPush10 {
	right: 83.33333%;
}

[dir='ltr'] .ms-smPush9 {
	left: 75%;
}

[dir='rtl'] .ms-smPush9 {
	right: 75%;
}

[dir='ltr'] .ms-smPush8 {
	left: 66.66667%;
}

[dir='rtl'] .ms-smPush8 {
	right: 66.66667%;
}

[dir='ltr'] .ms-smPush7 {
	left: 58.33333%;
}

[dir='rtl'] .ms-smPush7 {
	right: 58.33333%;
}

[dir='ltr'] .ms-smPush6 {
	left: 50%;
}

[dir='rtl'] .ms-smPush6 {
	right: 50%;
}

[dir='ltr'] .ms-smPush5 {
	left: 41.66667%;
}

[dir='rtl'] .ms-smPush5 {
	right: 41.66667%;
}

[dir='ltr'] .ms-smPush4 {
	left: 33.33333%;
}

[dir='rtl'] .ms-smPush4 {
	right: 33.33333%;
}

[dir='ltr'] .ms-smPush3 {
	left: 25%;
}

[dir='rtl'] .ms-smPush3 {
	right: 25%;
}

[dir='ltr'] .ms-smPush2 {
	left: 16.66667%;
}

[dir='rtl'] .ms-smPush2 {
	right: 16.66667%;
}

[dir='ltr'] .ms-smPush1 {
	left: 8.33333%;
}

[dir='rtl'] .ms-smPush1 {
	right: 8.33333%;
}

[dir='ltr'] .ms-smPush0 {
	left: auto;
}

[dir='rtl'] .ms-smPush0 {
	right: auto;
}

[dir='ltr'] .ms-smOffset11 {
	margin-left: 91.66667%;
}

[dir='rtl'] .ms-smOffset11 {
	margin-right: 91.66667%;
}

[dir='ltr'] .ms-smOffset10 {
	margin-left: 83.33333%;
}

[dir='rtl'] .ms-smOffset10 {
	margin-right: 83.33333%;
}

[dir='ltr'] .ms-smOffset9 {
	margin-left: 75%;
}

[dir='rtl'] .ms-smOffset9 {
	margin-right: 75%;
}

[dir='ltr'] .ms-smOffset8 {
	margin-left: 66.66667%;
}

[dir='rtl'] .ms-smOffset8 {
	margin-right: 66.66667%;
}

[dir='ltr'] .ms-smOffset7 {
	margin-left: 58.33333%;
}

[dir='rtl'] .ms-smOffset7 {
	margin-right: 58.33333%;
}

[dir='ltr'] .ms-smOffset6 {
	margin-left: 50%;
}

[dir='rtl'] .ms-smOffset6 {
	margin-right: 50%;
}

[dir='ltr'] .ms-smOffset5 {
	margin-left: 41.66667%;
}

[dir='rtl'] .ms-smOffset5 {
	margin-right: 41.66667%;
}

[dir='ltr'] .ms-smOffset4 {
	margin-left: 33.33333%;
}

[dir='rtl'] .ms-smOffset4 {
	margin-right: 33.33333%;
}

[dir='ltr'] .ms-smOffset3 {
	margin-left: 25%;
}

[dir='rtl'] .ms-smOffset3 {
	margin-right: 25%;
}

[dir='ltr'] .ms-smOffset2 {
	margin-left: 16.66667%;
}

[dir='rtl'] .ms-smOffset2 {
	margin-right: 16.66667%;
}

[dir='ltr'] .ms-smOffset1 {
	margin-left: 8.33333%;
}

[dir='rtl'] .ms-smOffset1 {
	margin-right: 8.33333%;
}

[dir='ltr'] .ms-smOffset0 {
	margin-left: 0;
}

[dir='rtl'] .ms-smOffset0 {
	margin-right: 0;
}

@media (min-width: 480px) {
	.ms-md12 {
		width: 100%;
	}
}

@media (min-width: 480px) {
	.ms-md11 {
		width: 91.66666666666666%;
	}
}

@media (min-width: 480px) {
	.ms-md10 {
		width: 83.33333333333334%;
	}
}

@media (min-width: 480px) {
	.ms-md9 {
		width: 75%;
	}
}

@media (min-width: 480px) {
	.ms-md8 {
		width: 66.66666666666666%;
	}
}

@media (min-width: 480px) {
	.ms-md7 {
		width: 58.333333333333336%;
	}
}

@media (min-width: 480px) {
	.ms-md6 {
		width: 50%;
	}
}

@media (min-width: 480px) {
	.ms-md5 {
		width: 41.66666666666667%;
	}
}

@media (min-width: 480px) {
	.ms-md4 {
		width: 33.33333333333333%;
	}
}

@media (min-width: 480px) {
	.ms-md3 {
		width: 25%;
	}
}

@media (min-width: 480px) {
	.ms-md2 {
		width: 16.666666666666664%;
	}
}

@media (min-width: 480px) {
	.ms-md1 {
		width: 8.333333333333332%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull12 {
		right: 100%;
	}

	[dir='rtl'] .ms-mdPull12 {
		left: 100%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull11 {
		right: 91.66667%;
	}

	[dir='rtl'] .ms-mdPull11 {
		left: 91.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull10 {
		right: 83.33333%;
	}

	[dir='rtl'] .ms-mdPull10 {
		left: 83.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull9 {
		right: 75%;
	}

	[dir='rtl'] .ms-mdPull9 {
		left: 75%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull8 {
		right: 66.66667%;
	}

	[dir='rtl'] .ms-mdPull8 {
		left: 66.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull7 {
		right: 58.33333%;
	}

	[dir='rtl'] .ms-mdPull7 {
		left: 58.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull6 {
		right: 50%;
	}

	[dir='rtl'] .ms-mdPull6 {
		left: 50%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull5 {
		right: 41.66667%;
	}

	[dir='rtl'] .ms-mdPull5 {
		left: 41.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull4 {
		right: 33.33333%;
	}

	[dir='rtl'] .ms-mdPull4 {
		left: 33.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull3 {
		right: 25%;
	}

	[dir='rtl'] .ms-mdPull3 {
		left: 25%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull2 {
		right: 16.66667%;
	}

	[dir='rtl'] .ms-mdPull2 {
		left: 16.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull1 {
		right: 8.33333%;
	}

	[dir='rtl'] .ms-mdPull1 {
		left: 8.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPull0 {
		right: auto;
	}

	[dir='rtl'] .ms-mdPull0 {
		left: auto;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush12 {
		left: 100%;
	}

	[dir='rtl'] .ms-mdPush12 {
		right: 100%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush11 {
		left: 91.66667%;
	}

	[dir='rtl'] .ms-mdPush11 {
		right: 91.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush10 {
		left: 83.33333%;
	}

	[dir='rtl'] .ms-mdPush10 {
		right: 83.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush9 {
		left: 75%;
	}

	[dir='rtl'] .ms-mdPush9 {
		right: 75%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush8 {
		left: 66.66667%;
	}

	[dir='rtl'] .ms-mdPush8 {
		right: 66.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush7 {
		left: 58.33333%;
	}

	[dir='rtl'] .ms-mdPush7 {
		right: 58.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush6 {
		left: 50%;
	}

	[dir='rtl'] .ms-mdPush6 {
		right: 50%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush5 {
		left: 41.66667%;
	}

	[dir='rtl'] .ms-mdPush5 {
		right: 41.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush4 {
		left: 33.33333%;
	}

	[dir='rtl'] .ms-mdPush4 {
		right: 33.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush3 {
		left: 25%;
	}

	[dir='rtl'] .ms-mdPush3 {
		right: 25%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush2 {
		left: 16.66667%;
	}

	[dir='rtl'] .ms-mdPush2 {
		right: 16.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush1 {
		left: 8.33333%;
	}

	[dir='rtl'] .ms-mdPush1 {
		right: 8.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdPush0 {
		left: auto;
	}

	[dir='rtl'] .ms-mdPush0 {
		right: auto;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset11 {
		margin-left: 91.66667%;
	}

	[dir='rtl'] .ms-mdOffset11 {
		margin-right: 91.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset10 {
		margin-left: 83.33333%;
	}

	[dir='rtl'] .ms-mdOffset10 {
		margin-right: 83.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset9 {
		margin-left: 75%;
	}

	[dir='rtl'] .ms-mdOffset9 {
		margin-right: 75%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset8 {
		margin-left: 66.66667%;
	}

	[dir='rtl'] .ms-mdOffset8 {
		margin-right: 66.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset7 {
		margin-left: 58.33333%;
	}

	[dir='rtl'] .ms-mdOffset7 {
		margin-right: 58.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset6 {
		margin-left: 50%;
	}

	[dir='rtl'] .ms-mdOffset6 {
		margin-right: 50%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset5 {
		margin-left: 41.66667%;
	}

	[dir='rtl'] .ms-mdOffset5 {
		margin-right: 41.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset4 {
		margin-left: 33.33333%;
	}

	[dir='rtl'] .ms-mdOffset4 {
		margin-right: 33.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset3 {
		margin-left: 25%;
	}

	[dir='rtl'] .ms-mdOffset3 {
		margin-right: 25%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset2 {
		margin-left: 16.66667%;
	}

	[dir='rtl'] .ms-mdOffset2 {
		margin-right: 16.66667%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset1 {
		margin-left: 8.33333%;
	}

	[dir='rtl'] .ms-mdOffset1 {
		margin-right: 8.33333%;
	}
}

@media (min-width: 480px) {
	[dir='ltr'] .ms-mdOffset0 {
		margin-left: 0;
	}

	[dir='rtl'] .ms-mdOffset0 {
		margin-right: 0;
	}
}

@media (min-width: 640px) {
	.ms-lg12 {
		width: 100%;
	}
}

@media (min-width: 640px) {
	.ms-lg11 {
		width: 91.66666666666666%;
	}
}

@media (min-width: 640px) {
	.ms-lg10 {
		width: 83.33333333333334%;
	}
}

@media (min-width: 640px) {
	.ms-lg9 {
		width: 75%;
	}
}

@media (min-width: 640px) {
	.ms-lg8 {
		width: 66.66666666666666%;
	}
}

@media (min-width: 640px) {
	.ms-lg7 {
		width: 58.333333333333336%;
	}
}

@media (min-width: 640px) {
	.ms-lg6 {
		width: 50%;
	}
}

@media (min-width: 640px) {
	.ms-lg5 {
		width: 41.66666666666667%;
	}
}

@media (min-width: 640px) {
	.ms-lg4 {
		width: 33.33333333333333%;
	}
}

@media (min-width: 640px) {
	.ms-lg3 {
		width: 25%;
	}
}

@media (min-width: 640px) {
	.ms-lg2 {
		width: 16.666666666666664%;
	}
}

@media (min-width: 640px) {
	.ms-lg1 {
		width: 8.333333333333332%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull12 {
		right: 100%;
	}

	[dir='rtl'] .ms-lgPull12 {
		left: 100%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull11 {
		right: 91.66667%;
	}

	[dir='rtl'] .ms-lgPull11 {
		left: 91.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull10 {
		right: 83.33333%;
	}

	[dir='rtl'] .ms-lgPull10 {
		left: 83.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull9 {
		right: 75%;
	}

	[dir='rtl'] .ms-lgPull9 {
		left: 75%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull8 {
		right: 66.66667%;
	}

	[dir='rtl'] .ms-lgPull8 {
		left: 66.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull7 {
		right: 58.33333%;
	}

	[dir='rtl'] .ms-lgPull7 {
		left: 58.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull6 {
		right: 50%;
	}

	[dir='rtl'] .ms-lgPull6 {
		left: 50%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull5 {
		right: 41.66667%;
	}

	[dir='rtl'] .ms-lgPull5 {
		left: 41.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull4 {
		right: 33.33333%;
	}

	[dir='rtl'] .ms-lgPull4 {
		left: 33.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull3 {
		right: 25%;
	}

	[dir='rtl'] .ms-lgPull3 {
		left: 25%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull2 {
		right: 16.66667%;
	}

	[dir='rtl'] .ms-lgPull2 {
		left: 16.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull1 {
		right: 8.33333%;
	}

	[dir='rtl'] .ms-lgPull1 {
		left: 8.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPull0 {
		right: auto;
	}

	[dir='rtl'] .ms-lgPull0 {
		left: auto;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush12 {
		left: 100%;
	}

	[dir='rtl'] .ms-lgPush12 {
		right: 100%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush11 {
		left: 91.66667%;
	}

	[dir='rtl'] .ms-lgPush11 {
		right: 91.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush10 {
		left: 83.33333%;
	}

	[dir='rtl'] .ms-lgPush10 {
		right: 83.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush9 {
		left: 75%;
	}

	[dir='rtl'] .ms-lgPush9 {
		right: 75%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush8 {
		left: 66.66667%;
	}

	[dir='rtl'] .ms-lgPush8 {
		right: 66.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush7 {
		left: 58.33333%;
	}

	[dir='rtl'] .ms-lgPush7 {
		right: 58.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush6 {
		left: 50%;
	}

	[dir='rtl'] .ms-lgPush6 {
		right: 50%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush5 {
		left: 41.66667%;
	}

	[dir='rtl'] .ms-lgPush5 {
		right: 41.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush4 {
		left: 33.33333%;
	}

	[dir='rtl'] .ms-lgPush4 {
		right: 33.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush3 {
		left: 25%;
	}

	[dir='rtl'] .ms-lgPush3 {
		right: 25%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush2 {
		left: 16.66667%;
	}

	[dir='rtl'] .ms-lgPush2 {
		right: 16.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush1 {
		left: 8.33333%;
	}

	[dir='rtl'] .ms-lgPush1 {
		right: 8.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgPush0 {
		left: auto;
	}

	[dir='rtl'] .ms-lgPush0 {
		right: auto;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset11 {
		margin-left: 91.66667%;
	}

	[dir='rtl'] .ms-lgOffset11 {
		margin-right: 91.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset10 {
		margin-left: 83.33333%;
	}

	[dir='rtl'] .ms-lgOffset10 {
		margin-right: 83.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset9 {
		margin-left: 75%;
	}

	[dir='rtl'] .ms-lgOffset9 {
		margin-right: 75%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset8 {
		margin-left: 66.66667%;
	}

	[dir='rtl'] .ms-lgOffset8 {
		margin-right: 66.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset7 {
		margin-left: 58.33333%;
	}

	[dir='rtl'] .ms-lgOffset7 {
		margin-right: 58.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset6 {
		margin-left: 50%;
	}

	[dir='rtl'] .ms-lgOffset6 {
		margin-right: 50%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset5 {
		margin-left: 41.66667%;
	}

	[dir='rtl'] .ms-lgOffset5 {
		margin-right: 41.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset4 {
		margin-left: 33.33333%;
	}

	[dir='rtl'] .ms-lgOffset4 {
		margin-right: 33.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset3 {
		margin-left: 25%;
	}

	[dir='rtl'] .ms-lgOffset3 {
		margin-right: 25%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset2 {
		margin-left: 16.66667%;
	}

	[dir='rtl'] .ms-lgOffset2 {
		margin-right: 16.66667%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset1 {
		margin-left: 8.33333%;
	}

	[dir='rtl'] .ms-lgOffset1 {
		margin-right: 8.33333%;
	}
}

@media (min-width: 640px) {
	[dir='ltr'] .ms-lgOffset0 {
		margin-left: 0;
	}

	[dir='rtl'] .ms-lgOffset0 {
		margin-right: 0;
	}
}

@media (min-width: 1024px) {
	.ms-xl12 {
		width: 100%;
	}
}

@media (min-width: 1024px) {
	.ms-xl11 {
		width: 91.66666666666666%;
	}
}

@media (min-width: 1024px) {
	.ms-xl10 {
		width: 83.33333333333334%;
	}
}

@media (min-width: 1024px) {
	.ms-xl9 {
		width: 75%;
	}
}

@media (min-width: 1024px) {
	.ms-xl8 {
		width: 66.66666666666666%;
	}
}

@media (min-width: 1024px) {
	.ms-xl7 {
		width: 58.333333333333336%;
	}
}

@media (min-width: 1024px) {
	.ms-xl6 {
		width: 50%;
	}
}

@media (min-width: 1024px) {
	.ms-xl5 {
		width: 41.66666666666667%;
	}
}

@media (min-width: 1024px) {
	.ms-xl4 {
		width: 33.33333333333333%;
	}
}

@media (min-width: 1024px) {
	.ms-xl3 {
		width: 25%;
	}
}

@media (min-width: 1024px) {
	.ms-xl2 {
		width: 16.666666666666664%;
	}
}

@media (min-width: 1024px) {
	.ms-xl1 {
		width: 8.333333333333332%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull12 {
		right: 100%;
	}

	[dir='rtl'] .ms-xlPull12 {
		left: 100%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull11 {
		right: 91.66667%;
	}

	[dir='rtl'] .ms-xlPull11 {
		left: 91.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull10 {
		right: 83.33333%;
	}

	[dir='rtl'] .ms-xlPull10 {
		left: 83.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull9 {
		right: 75%;
	}

	[dir='rtl'] .ms-xlPull9 {
		left: 75%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull8 {
		right: 66.66667%;
	}

	[dir='rtl'] .ms-xlPull8 {
		left: 66.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull7 {
		right: 58.33333%;
	}

	[dir='rtl'] .ms-xlPull7 {
		left: 58.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull6 {
		right: 50%;
	}

	[dir='rtl'] .ms-xlPull6 {
		left: 50%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull5 {
		right: 41.66667%;
	}

	[dir='rtl'] .ms-xlPull5 {
		left: 41.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull4 {
		right: 33.33333%;
	}

	[dir='rtl'] .ms-xlPull4 {
		left: 33.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull3 {
		right: 25%;
	}

	[dir='rtl'] .ms-xlPull3 {
		left: 25%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull2 {
		right: 16.66667%;
	}

	[dir='rtl'] .ms-xlPull2 {
		left: 16.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull1 {
		right: 8.33333%;
	}

	[dir='rtl'] .ms-xlPull1 {
		left: 8.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPull0 {
		right: auto;
	}

	[dir='rtl'] .ms-xlPull0 {
		left: auto;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush12 {
		left: 100%;
	}

	[dir='rtl'] .ms-xlPush12 {
		right: 100%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush11 {
		left: 91.66667%;
	}

	[dir='rtl'] .ms-xlPush11 {
		right: 91.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush10 {
		left: 83.33333%;
	}

	[dir='rtl'] .ms-xlPush10 {
		right: 83.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush9 {
		left: 75%;
	}

	[dir='rtl'] .ms-xlPush9 {
		right: 75%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush8 {
		left: 66.66667%;
	}

	[dir='rtl'] .ms-xlPush8 {
		right: 66.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush7 {
		left: 58.33333%;
	}

	[dir='rtl'] .ms-xlPush7 {
		right: 58.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush6 {
		left: 50%;
	}

	[dir='rtl'] .ms-xlPush6 {
		right: 50%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush5 {
		left: 41.66667%;
	}

	[dir='rtl'] .ms-xlPush5 {
		right: 41.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush4 {
		left: 33.33333%;
	}

	[dir='rtl'] .ms-xlPush4 {
		right: 33.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush3 {
		left: 25%;
	}

	[dir='rtl'] .ms-xlPush3 {
		right: 25%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush2 {
		left: 16.66667%;
	}

	[dir='rtl'] .ms-xlPush2 {
		right: 16.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush1 {
		left: 8.33333%;
	}

	[dir='rtl'] .ms-xlPush1 {
		right: 8.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlPush0 {
		left: auto;
	}

	[dir='rtl'] .ms-xlPush0 {
		right: auto;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset11 {
		margin-left: 91.66667%;
	}

	[dir='rtl'] .ms-xlOffset11 {
		margin-right: 91.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset10 {
		margin-left: 83.33333%;
	}

	[dir='rtl'] .ms-xlOffset10 {
		margin-right: 83.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset9 {
		margin-left: 75%;
	}

	[dir='rtl'] .ms-xlOffset9 {
		margin-right: 75%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset8 {
		margin-left: 66.66667%;
	}

	[dir='rtl'] .ms-xlOffset8 {
		margin-right: 66.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset7 {
		margin-left: 58.33333%;
	}

	[dir='rtl'] .ms-xlOffset7 {
		margin-right: 58.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset6 {
		margin-left: 50%;
	}

	[dir='rtl'] .ms-xlOffset6 {
		margin-right: 50%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset5 {
		margin-left: 41.66667%;
	}

	[dir='rtl'] .ms-xlOffset5 {
		margin-right: 41.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset4 {
		margin-left: 33.33333%;
	}

	[dir='rtl'] .ms-xlOffset4 {
		margin-right: 33.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset3 {
		margin-left: 25%;
	}

	[dir='rtl'] .ms-xlOffset3 {
		margin-right: 25%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset2 {
		margin-left: 16.66667%;
	}

	[dir='rtl'] .ms-xlOffset2 {
		margin-right: 16.66667%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset1 {
		margin-left: 8.33333%;
	}

	[dir='rtl'] .ms-xlOffset1 {
		margin-right: 8.33333%;
	}
}

@media (min-width: 1024px) {
	[dir='ltr'] .ms-xlOffset0 {
		margin-left: 0;
	}

	[dir='rtl'] .ms-xlOffset0 {
		margin-right: 0;
	}
}

@media (min-width: 1366px) {
	.ms-xxl12 {
		width: 100%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl11 {
		width: 91.66666666666666%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl10 {
		width: 83.33333333333334%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl9 {
		width: 75%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl8 {
		width: 66.66666666666666%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl7 {
		width: 58.333333333333336%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl6 {
		width: 50%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl5 {
		width: 41.66666666666667%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl4 {
		width: 33.33333333333333%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl3 {
		width: 25%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl2 {
		width: 16.666666666666664%;
	}
}

@media (min-width: 1366px) {
	.ms-xxl1 {
		width: 8.333333333333332%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull12 {
		right: 100%;
	}

	[dir='rtl'] .ms-xxlPull12 {
		left: 100%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull11 {
		right: 91.66667%;
	}

	[dir='rtl'] .ms-xxlPull11 {
		left: 91.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull10 {
		right: 83.33333%;
	}

	[dir='rtl'] .ms-xxlPull10 {
		left: 83.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull9 {
		right: 75%;
	}

	[dir='rtl'] .ms-xxlPull9 {
		left: 75%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull8 {
		right: 66.66667%;
	}

	[dir='rtl'] .ms-xxlPull8 {
		left: 66.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull7 {
		right: 58.33333%;
	}

	[dir='rtl'] .ms-xxlPull7 {
		left: 58.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull6 {
		right: 50%;
	}

	[dir='rtl'] .ms-xxlPull6 {
		left: 50%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull5 {
		right: 41.66667%;
	}

	[dir='rtl'] .ms-xxlPull5 {
		left: 41.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull4 {
		right: 33.33333%;
	}

	[dir='rtl'] .ms-xxlPull4 {
		left: 33.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull3 {
		right: 25%;
	}

	[dir='rtl'] .ms-xxlPull3 {
		left: 25%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull2 {
		right: 16.66667%;
	}

	[dir='rtl'] .ms-xxlPull2 {
		left: 16.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull1 {
		right: 8.33333%;
	}

	[dir='rtl'] .ms-xxlPull1 {
		left: 8.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPull0 {
		right: auto;
	}

	[dir='rtl'] .ms-xxlPull0 {
		left: auto;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush12 {
		left: 100%;
	}

	[dir='rtl'] .ms-xxlPush12 {
		right: 100%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush11 {
		left: 91.66667%;
	}

	[dir='rtl'] .ms-xxlPush11 {
		right: 91.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush10 {
		left: 83.33333%;
	}

	[dir='rtl'] .ms-xxlPush10 {
		right: 83.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush9 {
		left: 75%;
	}

	[dir='rtl'] .ms-xxlPush9 {
		right: 75%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush8 {
		left: 66.66667%;
	}

	[dir='rtl'] .ms-xxlPush8 {
		right: 66.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush7 {
		left: 58.33333%;
	}

	[dir='rtl'] .ms-xxlPush7 {
		right: 58.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush6 {
		left: 50%;
	}

	[dir='rtl'] .ms-xxlPush6 {
		right: 50%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush5 {
		left: 41.66667%;
	}

	[dir='rtl'] .ms-xxlPush5 {
		right: 41.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush4 {
		left: 33.33333%;
	}

	[dir='rtl'] .ms-xxlPush4 {
		right: 33.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush3 {
		left: 25%;
	}

	[dir='rtl'] .ms-xxlPush3 {
		right: 25%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush2 {
		left: 16.66667%;
	}

	[dir='rtl'] .ms-xxlPush2 {
		right: 16.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush1 {
		left: 8.33333%;
	}

	[dir='rtl'] .ms-xxlPush1 {
		right: 8.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlPush0 {
		left: auto;
	}

	[dir='rtl'] .ms-xxlPush0 {
		right: auto;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset11 {
		margin-left: 91.66667%;
	}

	[dir='rtl'] .ms-xxlOffset11 {
		margin-right: 91.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset10 {
		margin-left: 83.33333%;
	}

	[dir='rtl'] .ms-xxlOffset10 {
		margin-right: 83.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset9 {
		margin-left: 75%;
	}

	[dir='rtl'] .ms-xxlOffset9 {
		margin-right: 75%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset8 {
		margin-left: 66.66667%;
	}

	[dir='rtl'] .ms-xxlOffset8 {
		margin-right: 66.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset7 {
		margin-left: 58.33333%;
	}

	[dir='rtl'] .ms-xxlOffset7 {
		margin-right: 58.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset6 {
		margin-left: 50%;
	}

	[dir='rtl'] .ms-xxlOffset6 {
		margin-right: 50%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset5 {
		margin-left: 41.66667%;
	}

	[dir='rtl'] .ms-xxlOffset5 {
		margin-right: 41.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset4 {
		margin-left: 33.33333%;
	}

	[dir='rtl'] .ms-xxlOffset4 {
		margin-right: 33.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset3 {
		margin-left: 25%;
	}

	[dir='rtl'] .ms-xxlOffset3 {
		margin-right: 25%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset2 {
		margin-left: 16.66667%;
	}

	[dir='rtl'] .ms-xxlOffset2 {
		margin-right: 16.66667%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset1 {
		margin-left: 8.33333%;
	}

	[dir='rtl'] .ms-xxlOffset1 {
		margin-right: 8.33333%;
	}
}

@media (min-width: 1366px) {
	[dir='ltr'] .ms-xxlOffset0 {
		margin-left: 0;
	}

	[dir='rtl'] .ms-xxlOffset0 {
		margin-right: 0;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl12 {
		width: 100%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl11 {
		width: 91.66666666666666%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl10 {
		width: 83.33333333333334%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl9 {
		width: 75%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl8 {
		width: 66.66666666666666%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl7 {
		width: 58.333333333333336%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl6 {
		width: 50%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl5 {
		width: 41.66666666666667%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl4 {
		width: 33.33333333333333%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl3 {
		width: 25%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl2 {
		width: 16.666666666666664%;
	}
}

@media (min-width: 1920px) {
	.ms-xxxl1 {
		width: 8.333333333333332%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull12 {
		right: 100%;
	}

	[dir='rtl'] .ms-xxxlPull12 {
		left: 100%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull11 {
		right: 91.66667%;
	}

	[dir='rtl'] .ms-xxxlPull11 {
		left: 91.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull10 {
		right: 83.33333%;
	}

	[dir='rtl'] .ms-xxxlPull10 {
		left: 83.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull9 {
		right: 75%;
	}

	[dir='rtl'] .ms-xxxlPull9 {
		left: 75%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull8 {
		right: 66.66667%;
	}

	[dir='rtl'] .ms-xxxlPull8 {
		left: 66.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull7 {
		right: 58.33333%;
	}

	[dir='rtl'] .ms-xxxlPull7 {
		left: 58.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull6 {
		right: 50%;
	}

	[dir='rtl'] .ms-xxxlPull6 {
		left: 50%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull5 {
		right: 41.66667%;
	}

	[dir='rtl'] .ms-xxxlPull5 {
		left: 41.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull4 {
		right: 33.33333%;
	}

	[dir='rtl'] .ms-xxxlPull4 {
		left: 33.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull3 {
		right: 25%;
	}

	[dir='rtl'] .ms-xxxlPull3 {
		left: 25%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull2 {
		right: 16.66667%;
	}

	[dir='rtl'] .ms-xxxlPull2 {
		left: 16.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull1 {
		right: 8.33333%;
	}

	[dir='rtl'] .ms-xxxlPull1 {
		left: 8.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPull0 {
		right: auto;
	}

	[dir='rtl'] .ms-xxxlPull0 {
		left: auto;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush12 {
		left: 100%;
	}

	[dir='rtl'] .ms-xxxlPush12 {
		right: 100%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush11 {
		left: 91.66667%;
	}

	[dir='rtl'] .ms-xxxlPush11 {
		right: 91.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush10 {
		left: 83.33333%;
	}

	[dir='rtl'] .ms-xxxlPush10 {
		right: 83.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush9 {
		left: 75%;
	}

	[dir='rtl'] .ms-xxxlPush9 {
		right: 75%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush8 {
		left: 66.66667%;
	}

	[dir='rtl'] .ms-xxxlPush8 {
		right: 66.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush7 {
		left: 58.33333%;
	}

	[dir='rtl'] .ms-xxxlPush7 {
		right: 58.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush6 {
		left: 50%;
	}

	[dir='rtl'] .ms-xxxlPush6 {
		right: 50%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush5 {
		left: 41.66667%;
	}

	[dir='rtl'] .ms-xxxlPush5 {
		right: 41.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush4 {
		left: 33.33333%;
	}

	[dir='rtl'] .ms-xxxlPush4 {
		right: 33.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush3 {
		left: 25%;
	}

	[dir='rtl'] .ms-xxxlPush3 {
		right: 25%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush2 {
		left: 16.66667%;
	}

	[dir='rtl'] .ms-xxxlPush2 {
		right: 16.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush1 {
		left: 8.33333%;
	}

	[dir='rtl'] .ms-xxxlPush1 {
		right: 8.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlPush0 {
		left: auto;
	}

	[dir='rtl'] .ms-xxxlPush0 {
		right: auto;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset11 {
		margin-left: 91.66667%;
	}

	[dir='rtl'] .ms-xxxlOffset11 {
		margin-right: 91.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset10 {
		margin-left: 83.33333%;
	}

	[dir='rtl'] .ms-xxxlOffset10 {
		margin-right: 83.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset9 {
		margin-left: 75%;
	}

	[dir='rtl'] .ms-xxxlOffset9 {
		margin-right: 75%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset8 {
		margin-left: 66.66667%;
	}

	[dir='rtl'] .ms-xxxlOffset8 {
		margin-right: 66.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset7 {
		margin-left: 58.33333%;
	}

	[dir='rtl'] .ms-xxxlOffset7 {
		margin-right: 58.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset6 {
		margin-left: 50%;
	}

	[dir='rtl'] .ms-xxxlOffset6 {
		margin-right: 50%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset5 {
		margin-left: 41.66667%;
	}

	[dir='rtl'] .ms-xxxlOffset5 {
		margin-right: 41.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset4 {
		margin-left: 33.33333%;
	}

	[dir='rtl'] .ms-xxxlOffset4 {
		margin-right: 33.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset3 {
		margin-left: 25%;
	}

	[dir='rtl'] .ms-xxxlOffset3 {
		margin-right: 25%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset2 {
		margin-left: 16.66667%;
	}

	[dir='rtl'] .ms-xxxlOffset2 {
		margin-right: 16.66667%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset1 {
		margin-left: 8.33333%;
	}

	[dir='rtl'] .ms-xxxlOffset1 {
		margin-right: 8.33333%;
	}
}

@media (min-width: 1920px) {
	[dir='ltr'] .ms-xxxlOffset0 {
		margin-left: 0;
	}

	[dir='rtl'] .ms-xxxlOffset0 {
		margin-right: 0;
	}
}

.tox-notifications-container {
	display: none !important;
}
