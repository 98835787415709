.login {

    width: 800px;
    height: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
	button{
		width: 330px;
		margin: auto;
		height: 40px;
	
	}


}
