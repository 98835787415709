.loginAuth {

    width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;

    button {
        width: 200px;
        height: 40px;
        margin: auto;

    }

}